/* src/index.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600&display=swap");

@layer base {
  @font-face {
    font-family: "NULSHOCKRG-BOLD";
    src: url("./assets/fonts/nulshock/nulshock-bd.otf") format("opentype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
}

.font-headline {
  font-family: "NULSHOCKRG-BOLD", sans-serif;
  letter-spacing: 1px;
}

/* START DRONE SVG STYLES */
#drone-container {
  animation: float 5s ease-in-out infinite;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.animate-l-prop1 {
  animation: l-prop1 0.5s linear infinite;
  transform-origin: center;
}

.animate-l-prop2 {
  animation: l-prop2 0.5s linear infinite;
  transform-origin: center;
}

.animate-r-prop1 {
  animation: r-prop1 0.5s linear infinite;
  transform-origin: center;
}

.animate-r-prop2 {
  animation: r-prop2 0.5s linear infinite;
  transform-origin: center;
}
/* END DRONE SVG STYLES */

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
